const routes = [
	{
		path: '/',
		redirect: '/platform',
	},
	//我是平台端
    {
		path: '/platform/login',
		meta:{
			title:'平台登录'
		},
		component: () => import('../view/platform/login/login.vue')
	},
	{
		path: '/platform',
		redirect: '/platform/home',
		component: () => import('../view/platform/common/common.vue'),
		children: [
			{
				path: 'home',
				meta:{
					title:'首页'
				},
				component: () => import('../view/platform/home/home.vue')
			},{
				path: 'authAccount',
				meta:{
					title:'授权账号'
				},
				component: () => import('../view/platform/shop/authAccount.vue')
			},{
				path: 'myShop',
				meta:{
					title:'我的店铺'
				},
				component: () => import('../view/platform/shop/myShop.vue')
			},{
				path: 'recycle',
				meta:{
					title:'书籍回收站'
				},
				component: () => import('../view/platform/shop/recycle.vue')
			},
			{
				path: 'searchBook',
				meta:{
					title:'一键上书'
				},
				component: () => import('../view/platform/book/searchBook.vue')
			},{
				path: 'searchHistory',
				meta:{
					title:'搜书记录'
				},
				component: () => import('../view/platform/book/searchHistory.vue')
			},{
				path: 'orderSearch',
				meta:{
					title:'订单查询'
				},
				component: () => import('../view/platform/order/orderSearch.vue')
			},{
				path: 'orderList',
				meta:{
					title:'订单列表'
				},
				component: () => import('../view/platform/order/orderList.vue')
			},{
				path: 'sendGood',
				meta:{
					title:'发货列表'
				},
				component: () => import('../view/platform/order/sendGood.vue')
			},
		]
	}
]
export default routes;
