<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>

<script>


export default {
    name: 'App',

}
</script>

<style>
body {
    margin: 0;
}

.el-page-header {
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)
}

#app {
    /* font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px; */
}</style>
