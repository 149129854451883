import Vue from 'vue'
import  VueRouter from 'vue-router'
import routes from './route.js'

Vue.use(VueRouter)

const router = new VueRouter({
	routes,
	// duplicateNavigationPolicy: 'ignore' // 忽略重复导航  
})

router.beforeEach(function(to, from, next) {
	document.title = to.meta.title || 'BOOKS'; // 设置页面标题
	next();
})
export default router
